import React from 'react';
import { AppBar, Box, Dialog, LinearProgress, Tab, Tabs, Typography } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from './Table';
import { getCookie } from '../App';

// var uri = "https://drive.google.com/uc?export=download&id=17s78Cm24o0oyKUKLo_TlO3ZX9UJS-q-E"
// var file_name = "bb_doc_ctrl_v7.txt"
// var version = 7
var dataTo = []

var fernet = require('fernet');

const dataReceivedForTabs = {

}

var tabSelectedGlobal = null

class DocumentControl extends React.Component {



    constructor() {
        super()
        this.state = {
            strictMatch: true,
            muiTableKey: 0,
            data: [],
            loader: false,
            encProcess: true,
            disPlayContent: false,
            progress: 0,
            tabSelected: null,
            scrollWidth: 1000,
        }

        this.tableRef = React.createRef()
    }

    componentDidMount() {

        let access = getCookie("bb_ts_token")

        if ((window.location.host.includes("1loc1alhost:")) || (window.location.host.includes("pa1ram.bbedcindia.com"))) {
            this.setState({
                loader: true
            })
        } else {
            if (access) {
                if (this.state.tabSelected) {
                    this.processData(this.state.tabSelected)
                }
            }
        }
    }

    // handleFileChange(files) {
    //     if (files.length) {
    //         if (files[0].name.split(".")[0].split(" ")[0].substring(0, files[0].name.split(".")[0].split(" ")[0].length - 2).includes(file_name.split(".")[0].substring(0, file_name.split(".")[0].length - 2))) {
    //             try {
    //                 if (parseInt(files[0].name.split(".")[0].split(" ")[0].slice(-1)) === version) {
    //                     var reader = new FileReader();
    //                     reader.onload = (event) => {
    //                         // NOTE: event.target point to FileReader
    //                         var contents = event.target.result;
    //                         var lines = contents.split('\n');
    //                         this.setState({
    //                             encProcess: false
    //                         }, () => {
    //                             setTimeout(() => this.processData(lines[0]), 100)

    //                         })

    //                     };

    //                     reader.readAsText(files[0]);
    //                 } else {
    //                     alert("New version of data is available. Please Download the updated file.\nNote: Do not rename the file.")
    //                 }

    //             } catch (err) {
    //                 alert("Unable deect file version. Please do not change the file name! You can download the file again.")
    //             }

    //         } else {
    //             alert("Unable deect file version. Please do not change the file name! You can download the file again.")
    //             // this.setState({
    //             //     fileDownload: true
    //             // })
    //             this.getData()
    //         }

    //     }

    // }

    // getData() {
    //     // const link = document.createElement('a');
    //     // link.href = uri;
    //     // document.body.appendChild(link);
    //     // link.click();
    //     // document.body.removeChild(link);
    //     window.open(uri)
    //     window.location.reload()
    // }

    processData(tabSelected) {
        dataTo = []
        // this.setState({ })
        // console.log(dataReceivedForTabs)
        if (dataReceivedForTabs[tabSelected]) {
            dataTo = dataReceivedForTabs[tabSelected]
            setTimeout(() => {
                this.setState({
                    // columns: columns,
                    // data: dataTo.Sheet1,
                    loader: false,
                    disPlayContent: true,

                })
            }, 1000)
            return;
        }


        this.setState({ encProcess: true })
        var request = new XMLHttpRequest();
        request.open('GET', 'https://bb-doc-ctrl.nyc3.cdn.digitaloceanspaces.com/doc-ctrl/' + tabSelected + '.txt', true);
        request.setRequestHeader('Cache-Control', 'no-cache');
        request.send(null);
        request.onprogress = (pe) => {
            if (pe.lengthComputable) {
                this.setState({
                    progress: (pe.loaded / pe.total) * 100
                })
            }
        }
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                var type = request.getResponseHeader('Content-Type');
                if (type.indexOf("text") !== 1) {
                    // console.log(request.responseText)
                    var pushData = request.responseText;
                    // dataReceivedForTabs[tabSelected] = pushData
                    this.processData2(pushData, tabSelected)
                }
            } else if (request.readyState === 4 && request.status === 403) {
                alert("Data unavailable!")
                this.setState({
                    progress: 0,
                    loader: false
                })
                // window.location.reload()
            }
        }
    }

    processData2(pushData, tabSelected) {
        this.setState({
            encProcess: false
        }, () => {
            setTimeout(() => {
                try {
                    var data = pushData.split("&|&")
                    console.log(data.length)
                    for (let i = 1; i < data.length; i++) {
                        const element = data[i];
                        (decrypt(element))
                    }

                    // console.log(data[0].split("\"")[1], version)

                    dataReceivedForTabs[tabSelected] = dataTo

                    this.setState({
                        // columns: columns,
                        // data: dataTo.Sheet1,
                        loader: false,
                        disPlayContent: true,

                    })

                }
                catch (err) {
                    console.log(err)
                    alert("Error!. Please refresh")
                }
            }, 300)

        })
    }

    closeDialog = () => {
        this.setState({ loader: false })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    refCallback = el => {
        if (el) {
            this.setState({
                scrollHeight: window.innerHeight - el.getBoundingClientRect().y,
                scrollWidth: window.innerWidth - el.getBoundingClientRect().x
            })
        }
    }

    render() {

        const handleTabChange = (event, newValue) => {
            this.setState({
                tabSelected: newValue,
                disPlayContent: false,
                loader: true,
                progress: 0
            })
            tabSelectedGlobal = newValue
            this.processData(newValue)
        };

        return (
            <>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.tabSelected}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="OverHead Line" value={"overhead_line"} />
                        <Tab label="Towers Digital" value={"tower_digital"} />
                        <Tab label="Cabling" value={"cabling"} />
                        <Tab label="Substation" value={"substation"} />
                    </Tabs>
                </AppBar>
                <ToastContainer />

                {this.state.loader ?
                    <div>
                        <Dialog
                            disableBackdropClick
                            disableEscapeKeyDown
                            onClose={this.closeDialog}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.loader}
                        >
                            <div style={{ padding: '10px', }}>
                                {(this.state.encProcess) ? (
                                    // <div>
                                    //     <Typography>Current File Version: {file_name}</Typography>
                                    //     <Button color="primary" variant="contained" style={{ margin: '5px' }} onClick={() => {
                                    //         alert("Downloading...!\nNote: Do not rename the file.")
                                    //         window.open(uri)
                                    //     }} >Don't have file?. Please click here.</Button>
                                    //     <DropzoneArea
                                    //         acceptedFiles={['text/plain']}
                                    //         filesLimit={1}
                                    //         dropzoneText={'Drag and drop file downloaded from here. Note: File name looks like "bb_doc_ctrl_vX.txt"'}
                                    //         onChange={this.handleFileChange.bind(this)}
                                    //         maxFileSize={500000000}
                                    //     />
                                    // </div>
                                    <>
                                        <Box display="flex" flexDirection="row" p={1}>
                                            <Box p={1}>
                                                Receiving Data...!
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" p={1}>
                                            <Box p={1} style={{ width: '300px' }}>
                                                <LinearProgressWithLabel value={this.state.progress} />
                                            </Box>

                                        </Box>
                                    </>
                                ) : ("Decrypting...! Please wait until decryption is done")}

                            </div>
                        </Dialog>

                    </div> :
                    this.state.disPlayContent ?
                        <div ref={this.refCallback}
                        // style={{ height: this.state.scrollHeight + "px", width: this.state.scrollWidth + "px", overflowY: 'scroll' }}
                        >
                            {/* <FormControlLabel
                        style={{ marginTop: '80px' }}
                        control={
                            <Checkbox
                                checked={this.state.strictMatch}
                                onChange={this.handleChange}
                                name="strictMatch"
                                color="primary"
                            />
                        }
                        label="Strict Match"
                    /> */}
                            {this.state.scrollHeight ? <div style={{ zoom: 0.75, margin: '3px' }}>
                                <Table data={dataTo ? dataTo : []} tabSelected={tabSelectedGlobal} scrollHeigh={this.state.scrollHeight} />
                            </div> : null}


                        </div>
                        : <Box display={'flex'} justifyContent={'center'} alignItems="center" style={{ height: '60vh' }}>Click on the Tab above to download the data.</Box>


                }
            </>
        )
    }
}

async function decrypt(data) {
    try {
        var secret = new fernet.Secret("rQT_quGt_33oozTU-vFXWRgvfbZZK_eoCwftxnu8tbg=");
        var token = new fernet.Token({
            secret: secret,
            token: data,
            ttl: 0
        })
        // alert("token")
        // console.log(token.decode())
        var resp = JSON.parse(token.decode());
        // alert(resp.length)
        // console.log(resp.length)
        // console.log(dataTo)
        dataTo = [...dataTo, ...resp]
        // console.log(dataTo)
        // console.log("hap")
    } catch (err) {
        alert("Error! Contact EDC Team")
    }
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


// function downloadURI(uri, name) {
//     var link = document.createElement("a");
//     link.download = name;
//     link.href = uri;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     // delete link;
// }

export default DocumentControl