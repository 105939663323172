import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DocumentControl from './DocumentControler';

class DocumentControlHome extends Component {
    render() {
        return (
            <div>
                <AppBar position="static" >
                    <Toolbar>
                        <img src={"https://media-s3-us-east-1.ceros.com/balfour-beatty/images/2018/02/01/8d9dffee5460c61b16ecfe6dadfffa31/2017-balfour-beatty-logo-white.png?imageOpt=1&fit=bounds&width=210"} alt="Balfour Beatty" />
                        <h3>
                            PT&D Legacy Drawing/Document Search
                        </h3>
                        <p style={{marginLeft: '12px'}}>
                            Powered by PaRaM
                        </p>
                    </Toolbar>
                </AppBar>
                <DocumentControl />
            </div>
        );
    }
}

export default DocumentControlHome;