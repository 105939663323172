import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DocumentControlHome from './Components/DocumentControlHome';
import GenerateEncrypetedFile from './Components/GenerateEncrypetedFile';
import React, { Component } from 'react';
import Service from "./StaticHandle/networkutils";

// function decryptData(data) {
//   try {
//     var bytes = CryptoJS.AES.decrypt(data, 'Q424n6oHKX2UXj1wb0UxK9');
//     var originalText = bytes.toString(CryptoJS.enc.Utf8);
//     if (originalText === "") {
//       return null;
//     } else {
//       return originalText;
//     }
//   } catch (error) {
//     return null;
//   }
// }

// function setCookie(name, value, options = {}) {

//   if (window.location.hostname === 'localhost' || window.location.protocol === 'http:') {
//     localStorage.setItem(name, value);
//   } else {
//     localStorage.removeItem(name);
//     var ciphertext = CryptoJS.AES.encrypt(value, 'Q424n6oHKX2UXj1wb0UxK9').toString();
//     // console.log(ciphertext)

//     options = {
//       path: '/',
//       // add other defaults here if necessary
//       ...options
//     };

//     if (options.expires instanceof Date) {
//       options.expires = options.expires.toUTCString();
//     }

//     let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(ciphertext);

//     for (let optionKey in options) {
//       updatedCookie += "; " + optionKey;
//       let optionValue = options[optionKey];
//       if (optionValue !== true) {
//         updatedCookie += "=" + optionValue;
//       }
//     }

//     document.cookie = updatedCookie;
//   }
// }

export function getCookie(name) {
  if (window.location.hostname === 'localhost' || window.location.protocol === 'http:') {
    return localStorage.getItem(name);
  } else {
    /* eslint-disable-next-line */
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));

    var value = matches ? decodeURIComponent(matches[1]) : undefined;
    if (value) {
      try {
        var bytes = CryptoJS.AES.decrypt(value, 'Q424n6oHKX2UXj1wb0UxK9');
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        if (originalText === "") {
          return null;
        } else {
          return originalText;
        }

      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

}

var CryptoJS = require("crypto-js");

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isAuth: false
    }
  }

  componentDidMount() {
    if (!getCookie("bb_ts_token")) {
      window.location = "https://bbparam.com/ext/auth?url=" + window.location.host
    }else {
      this.checkToken()
    }
    
  }

  checkToken() {
    Service.get(`/bb/api/token/valid/check/`, {
      headers: {
        Authorization: "Token " + getCookie("bb_ts_token"),
      },
    })
      .then(res => {
        // var access = JSON.parse(getCookie("bb_ts_access"))
        // console.log(access)
        // if (!(access.access && access.access.l111a && access.access.l111a.l111a_e)) {
        //   alert("You don't have access")
        //   this.setState({ isAuth: false })
        // } else {
        //   this.setState({
        //     isAuth: true
        //   })
        // }
        this.setState({
          isAuth: true
        })
      })
      .catch(error => {

        console.log(error)
        if (error && error.status === 401) {
          alert("Session Expired - Refreshing Session")
          window.location = "https://bbparam.com/ext/auth?url=" + window.location.host
        }
      });
  }

  getAccessImdt() {

    Service.get(`/bb/api/token/access/`, {
      headers: {
        Authorization: "Token " + getCookie("bb_ts_token"),
      },
    })
      .then(res => {
        if (res.data && res.data[0]) {
          var access = res.data[0]
          if (!(access.access && access.access.l111a && access.access.l111a.l111a_e)) {
            alert("You don't have access")
            this.setState({ isAuth: false })
          }
        }
      })
      .catch(error => {
      });

  }

  render() {
    return (
      this.state.isAuth ?
        <div >
          <BrowserRouter >
            <Routes>
              <Route exact path="/" element={<DocumentControlHome />} />
              <Route path="/create/encrypt/file" element={<GenerateEncrypetedFile />} />
            </Routes>
          </BrowserRouter>
        </div>
        : "Authenticating...!"
    );
  }
}

export default App;