// The axios wrapper
import axios from "axios";
// import { resetCountTime } from "../Helper/CommonThings";
// import { deleteCookie } from "../Helper/CookieHelper";

// const host_name = window.location.hostname
const host_name = window.location.hostname
let domain = null
let domain2 = null

if (host_name.includes('bbparam.com')) {

  domain = 'https://api.bbparam.com'

} else if (host_name.includes('param.bbedcindia.com')) {

  // domain = 'https://api.bbparam.com'
  domain = 'http://param.bbedcindia.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'

} else if (host_name.includes('gnidct.bbedcindia.com')) {

  // domain = 'https://api.bbparam.com'
  domain = 'http://param.bbedcindia.com:8000'

} else {

  domain = 'http://param.bbedcindia.com:8000'
  // domain = 'https://reporting-pro.bbparam.com'

}

if (domain.includes('api.bbparam.com')) {
  domain2 = 'https://reporting-pro.bbparam.com'
} else if (domain.includes('param.bbedcindia.com')) {
  domain2 = 'http://param.bbedcindia.com:8000'
} else if (domain.includes('gnidct.bbedcindia.com')) {
  domain2 = 'http://gnidct.bbedcindia.com:7000'
} else {
  domain2 = 'http://gnidct.bbedcindia.com:7000'
}

export function getApiDomain() {
  return domain
}

class Service {
  constructor() {
    let service = null;

    service = axios.create({
      headers: {
        // Authorization: this.getAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });
    axios.defaults.timeout = 1000 * 600;
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.state = {
      statusCode: null
    };
    // resetCountTime();
  }
  // async getAccessToken() {
  //   try {
  //     const value = await localStorage.getItem("id_token");
  //     if (value !== null) {
  //       return value;
  //     } else {
  //       return "";
  //     }
  //   } catch (error) {
  //     return "";
  //   }
  // }

  // handleSuccess(response) {
  //   return response;
  // }


  // handleError = error => {
  //   // console.log(error.response.status)
  //   switch (error.response.status) {
  //     case 401:
  //       // deleteCookie("bb_ts_token");
  //       // deleteCookie("bb_ts_user");
  //       // deleteCookie("bb_ts_access");
  //       if (window.location.pathname !== "/") {
  //         window.location.href = "/"
  //       }

  //       break;
  //     case 409:
  //       console.log(error);
  //       //alert(error.response.message);
  //       console.log("409: Something went wrong");
  //       break;
  //     case 404:
  //       console.log("404: Something went wrong!");
  //       break;
  //     case 500:
  //       console.log("500: Something went wrong!");
  //       break;
  //     case 502:
  //       console.log("502: Something went wrong!");
  //       break;
  //     default:
  //       // console.log(error);
  //       break;
  //   }
  // };


  get(path, options) {
    // resetCountTime(path);
    return this.service
      .get(domain + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  patch(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "PATCH",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "UPDATE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "POST",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "PUT",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "DELETE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }








  get2(path, options) {
    // resetCountTime(path);
    return this.service
      .get(domain2 + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => {
        if (error.response.status === 403) {
          this.get(path, options)
        } else {
          Promise.reject(error.response)
        }
      });
  }

  patch2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "PATCH",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "UPDATE",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "POST",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "PUT",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "DELETE",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

}

export default new Service();
