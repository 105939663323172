import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';

const bbColorTheme = createTheme({
  palette: {
    primary: {
      main: '#007681',
    },
    secondary: {
      main: '#EEBE86',
    },
    type: getDarkMode(),
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={bbColorTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

function getDarkMode() {
  const darkMode = localStorage.getItem("theme_mode")
  if (darkMode) {
    return darkMode
  } else {
    return 'light'
  }

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
