import { Alert } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import copy from 'copy-to-clipboard';
import { Link, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';
import { TablePagination, TablePaginationProps } from '@material-ui/core';

const customFilter = (term, rowData, key) => {
    let flag = false
    if (String(term).includes('&')) {
        let allmatch = false
        let textArr = String(term).trim().split('&')
        for (let i = 0; i < textArr.length; i++) {
            if (textArr[i] !== "") {
                if ((String(rowData[key]).toLowerCase().includes(String(textArr[i]).toLowerCase()))) {
                    allmatch = true
                } else {
                    allmatch = false
                    break
                }
            }
        }
        flag = allmatch
    }
    else {
        flag = String(rowData[key]).toLowerCase().includes(String(term).toLowerCase())
    }
    return flag
}

const customFilterWithoutKey = (term, rowData, dataTo) => {
    let flag = false
    if (String(term).includes('&')) {
        let allmatch = false
        let textArr = String(term).trim().split('&')
        for (let i = 0; i < textArr.length; i++) {
            if (textArr[i] !== "") {
                if ((String(dataTo).toLowerCase().includes(String(textArr[i]).toLowerCase()))) {
                    allmatch = true
                } else {
                    allmatch = false
                    break
                }
            }
        }
        flag = allmatch
    }
    else {
        flag = String(dataTo).toLowerCase().includes(String(term).toLowerCase())
    }
    return flag
}

class Table extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        // alert(this.props.scrollHeigh)
    }

    render() {
        return (
            !this.state.isLoading ?
                <div>
                    <MaterialTable
                        // style={{ minWidth: ((window.innerWidth * 0.75) * 1.76) + 'px' }}
                        key={this.props.tabSelected}
                        columns={[
                            { title: 'Count', field: 'Count', width: 200, hidden: this.props.tabSelected !== "overhead_line" },
                            {
                                title: 'Project IDs', field: 'Project IDs', width: 200,
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Project IDs')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Projects', field: 'Projects', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Projects')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Object', field: 'Object', cellStyle: {
                                    minWidth: 250
                                },
                                headerStyle: {
                                    minWidth: 250
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Object')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Name', field: 'Name', cellStyle: {
                                    minWidth: 250
                                },
                                headerStyle: {
                                    minWidth: 250
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Name')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Description', field: 'Description', cellStyle: {
                                    minWidth: 250
                                },
                                headerStyle: {
                                    minWidth: 250
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Description')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Type', field: 'Type', cellStyle: {
                                    minWidth: 250
                                },
                                headerStyle: {
                                    minWidth: 250
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Type')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Owner', field: 'Owner', cellStyle: {
                                    minWidth: 250
                                },
                                headerStyle: {
                                    minWidth: 250
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Owner')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Group ID', field: 'Group ID', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Group ID')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Last Modified Date', field: 'Last Modified Date', cellStyle: {
                                    minWidth: 250
                                },
                                headerStyle: {
                                    minWidth: 250
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'BIM Code', field: 'BIM Code', width: 200,
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'BIM Code')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },

                            {
                                title: 'Displayable Revisions', field: 'Displayable Revisions', cellStyle: {
                                    minWidth: 500
                                },
                                headerStyle: {
                                    minWidth: 500
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Displayable Revisions')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            {
                                title: 'Last Modifying User', field: 'Last Modifying User', width: 200,
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Last Modifying User')
                                },
                                hidden: this.props.tabSelected !== "overhead_line"
                            },
                            // { title: 'Last Modifying User', field: 'Last Modifying User', width: 200 },
                            {
                                title: 'File Location', field: 'File Location', width: 200,
                                hidden: this.props.tabSelected !== "overhead_line",
                                render: rowData => (rowData["File Location"]) ? <Link component="button"
                                    variant="body2" onClick={() => {
                                        // var isChrome = !!window.chrome;
                                        // console.log(rowData["File Location"])

                                        copy(rowData["File Location"])
                                        toast("Copied -> " + rowData["File Location"]);
                                    }}>
                                    Copy File Location
                                </Link> : null
                            },






                            //TowerDigital
                            {
                                title: 'S.No', field: 'S.No', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                }, hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Location', field: 'Location', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Location')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Drg No', field: 'Drg No', cellStyle: {
                                    minWidth: 125
                                },
                                headerStyle: {
                                    minWidth: 125
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Drg No')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Revision', field: 'Revision', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Revision')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'NGC / CEGB No ', field: 'NGC / CEGB No ', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'NGC / CEGB No ')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Description', field: 'Description', cellStyle: {
                                    minWidth: 400
                                },
                                headerStyle: {
                                    minWidth: 400
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Description')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Tower Type', field: 'Tower Type', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Tower Type')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Spec', field: 'Spec', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Spec')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'KV', field: 'KV', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'KV')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Other No', field: 'Other No', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'OTHER No')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'Teamcenter BB Item No.', field: 'Teamcenter BB Item No.', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Teamcenter BB Item No.')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'TC Upload Date', field: 'TC Upload Date', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                render: (rowData) => moment(ExcelDateToJSDate(rowData['TC Upload Date'])).format('DD-MM-YYYY'),
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilterWithoutKey(term, rowData, moment(ExcelDateToJSDate(rowData['TC Upload Date'])).format('DD-MM-YYYY'))
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },

                            {
                                title: 'TC Upload by:', field: 'TC Upload by:', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'TC Upload by:')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'TC Classification', field: 'TC Classification', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'TC Classification')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'TC PSE', field: 'TC PSE', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'TC PSE')
                                },
                                hidden: this.props.tabSelected !== "tower_digital"
                            },
                            {
                                title: 'File Location', field: 'File Location', width: 200,
                                hidden: this.props.tabSelected !== "tower_digital",
                                render: rowData => (rowData["File Location"]) ? <Link component="button"
                                    variant="body2" onClick={() => {
                                        // var isChrome = !!window.chrome;
                                        // console.log(rowData["File Location"])

                                        copy(rowData["File Location"])
                                        toast("Copied -> " + rowData["File Location"]);
                                    }}>
                                    Copy File Location
                                </Link> : null
                            },







                            //Cabling
                            {
                                title: 'Contract Reference', field: 'Contract Reference', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Contract Reference')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Site', field: 'Site', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Site')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'BB Ref No.', field: 'BB Ref No.', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'BB Ref No.')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Issue', field: 'Issue', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Issue')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Client 1 Ref No.', field: 'Client 1 Ref No.', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Client 1 Ref No.')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Client 2 Ref No.', field: 'Client 2 Ref No.', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Client 2 Ref No.')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Title', field: 'Title', cellStyle: {
                                    minWidth: 300
                                },
                                headerStyle: {
                                    minWidth: 300
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Title')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'DTN No.', field: 'DTN No.', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'DTN No.')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Created For', field: 'Created For', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Created For')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Date Issued for Construction', field: 'Date Issued for Construction', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Date Issued for Construction')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'To be "As Built"', field: 'To be "As Built"', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'To be "As Built"')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'Client Acceptance Date', field: 'Client Acceptance Date', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Client Acceptance Date')
                                },
                                hidden: this.props.tabSelected !== "cabling"
                            },
                            {
                                title: 'File Location', field: 'File location', width: 200,
                                hidden: this.props.tabSelected !== "cabling",
                                render: rowData => (rowData["File location"]) ? <Link component="button"
                                    variant="body2" onClick={() => {
                                        // var isChrome = !!window.chrome;
                                        // console.log(rowData["File Location"])

                                        copy(rowData["File location"])
                                        toast("Copied -> " + rowData["File location"]);
                                    }}>
                                    Copy File Location
                                </Link> : null
                            },







                            //Substation
                            {
                                title: 'Count', field: 'Count', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                }, hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Project IDs', field: 'Project IDs', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Project IDs')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Project Site', field: 'Project Site', cellStyle: {
                                    minWidth: 200
                                },
                                headerStyle: {
                                    minWidth: 200
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Project Site')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Filing Reference', field: 'Filing Reference', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Filing Reference')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Type', field: 'Type', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Type')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Bay/Circuit', field: 'Bay/Circuit', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Bay/Circuit')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Drawing/Document Title ', field: 'Drawing/Document Title ', cellStyle: {
                                    minWidth: 500
                                },
                                headerStyle: {
                                    minWidth: 500
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Drawing/Document Title ')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Nat. Grid Doc No. ', field: 'Nat. Grid Doc No. ', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Nat. Grid Doc No. ')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Originator Doc No. ', field: 'Originator Doc No. ', cellStyle: {
                                    minWidth: 150
                                },
                                headerStyle: {
                                    minWidth: 150
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Originator Doc No. ')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'Revision', field: 'Revision', cellStyle: {
                                    minWidth: 100
                                },
                                headerStyle: {
                                    minWidth: 100
                                },
                                customFilterAndSearch: (term, rowData) => {
                                    return customFilter(term, rowData, 'Revision')
                                },
                                hidden: this.props.tabSelected !== "substation"
                            },
                            {
                                title: 'File Location', field: 'File Location', width: 200,
                                hidden: this.props.tabSelected !== "substation",
                                render: rowData => (rowData["File Location"]) ? <Link component="button"
                                    variant="body2" onClick={() => {
                                        // var isChrome = !!window.chrome;
                                        // console.log(rowData["File Location"])

                                        copy(rowData["File Location"])
                                        toast("Copied -> " + rowData["File Location"]);
                                    }}>
                                    Copy File Location
                                </Link> : null
                            },


                        ]}
                        data={this.props.data}
                        options={{
                            paging: true,
                            pageSize: 250,
                            pageSizeOptions: [250, 500, 750, 1000, 1250, 1500, 2000],
                            maxBodyHeight: this.props.scrollHeigh > 1000 ? this.props.scrollHeigh * 1.13 : this.props.scrollHeigh > 750 ? this.props.scrollHeigh * 1.06 : this.props.scrollHeigh < 700 ? this.props.scrollHeigh * .985 : this.props.scrollHeigh ,
                            maxBodyWidth: window.innerWidth - 21,
                            headerStyle: { position: 'sticky', top: 0 },
                            showTitle: false,
                            filtering: true,
                            searchFieldAlignment: 'left',
                            doubleHorizontalScroll: true,
                            doubleVerticalScroll: true,
                            padding: 'dense',
                            grouping: true,
                            toolbarButtonAlignment: 'left',
                            searchFieldStyle: {
                                width: '700px'
                            }
                        }}
                        actions={[
                            {
                                icon: () => <Alert severity="info">Use "&" for multiple keyword search and filtering!</Alert>,
                                isFreeAction: true,
                                disabled: true
                            }
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                            Container: props => <Paper {...props} elevation={0} />
                        }}
                        localization={{ toolbar: { searchPlaceholder: 'Global Search' } }}
                    />
                </div>
                : null

        );
    }
}

function PatchedPagination(props: TablePaginationProps) {
    const {
        ActionsComponent,
        onChangePage,
        onChangeRowsPerPage,
        ...tablePaginationProps
    } = props;

    return (
        <TablePagination
            {...tablePaginationProps}
            // @ts-expect-error onChangePage was renamed to onPageChange
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                    // @ts-expect-error ActionsComponent is provided by material-table
                    <ActionsComponent
                        {...actionsComponentProps}
                        onChangePage={onPageChange}
                    />
                );
            }}
        />
    );
}

function ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
}

export default Table;