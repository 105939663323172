import { DropzoneArea } from 'material-ui-dropzone';
import React, { Component } from 'react';

var XLSX = require("xlsx");

var fileJSONData = null

var encryptedData = null

var totalChunk = null
var chunkProcessed = 0

var fernet = require('fernet');

class GenerateEncrypetedFile extends Component {

    handleSave(files) {
        if (files && files[0]) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, {
                    type: rABS ? "binary" : "array",
                    bookVBA: true
                });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws);

                fileJSONData = data

                const dataChunked = this.dataChunked()

                encryptedData = null

                for (let i = 0; i < dataChunked.length; i++) {
                    const element = dataChunked[i];
                    (encrypt(JSON.stringify(element)))
                }

            };

            if (rABS) {
                reader.readAsBinaryString(files[0]);
            } else {
                reader.readAsArrayBuffer(files[0]);
            }
        }
    }

    dataChunked() {
        let input = fileJSONData;
        let chunked = []
        let size = 50;

        console.log(input.length)

        for (let i = 0; i < input.length; i += size) {
            chunked.push(input.slice(i, i + size))
        }

        totalChunk = chunked.length

        return chunked
    }

    render() {
        return (
            <div>
                <DropzoneArea
                    onChange={this.handleSave.bind(this)}
                    acceptedFiles={['application/vnd.ms-excel', ".xlsx"]}
                    showPreviews={false}
                    maxFileSize={500000000}
                />
            </div>
        );
    }
}

async function encrypt(data) {
    try {
        var secret = new fernet.Secret("rQT_quGt_33oozTU-vFXWRgvfbZZK_eoCwftxnu8tbg=");
        var token = new fernet.Token({
            secret: secret,
            time: Date.parse(1),
            iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
        })
        
        // if (encryptedData) {
        encryptedData += "&|&" + token.encode(data) 
        // } else {
        //     encryptedData = token.encode(data)
        // }

        chunkProcessed += 1

        if (totalChunk === chunkProcessed) {
            console.log(totalChunk)
            downloadFile()
        }


    } catch (err) {
        alert("Error! Contact EDC Team")
    }
}

async function downloadFile() {
    const element = document.createElement("a");
    const file = new Blob([encryptedData], {
        type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "encrypted_file.txt";
    document.body.appendChild(element);
    element.click();
}

export default GenerateEncrypetedFile;